import React from 'react';
import notfound from '../icon/404.jpg'
const Notfound = () => {
    return (
        <div className='flex justify-center'>
            <img src={notfound} alt="" />
        </div>
    );
};

export default Notfound;